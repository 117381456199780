import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import { graphql, Link, StaticQuery } from "gatsby";

import Hero from "../../components/landingPage/Hero";
import Layout from "../../components/Layout";
import Cta from "../../components/Cta";

import Location from "../../components/landingPage/Location";
import PriceList from "../../components/landingPage/PriceList";
import Reviews from "../../components/landingPage/Reviews";
import Dermoscopy from "../../components/landingPage/Dermoscopy";
import Oncology from "../../components/landingPage/Oncology";
import Videodermoscopy from "../../components/landingPage/Videodermoscopy";
import Surgery from "../../components/landingPage/Surgery";
import Melanoma from "../../components/landingPage/Melanoma";
import Abcde from "../../components/landingPage/Abcde";
import Gallery from "../../components/landingPage/Gallery";
import Offer from "../../components/landingPage/Offer";
import Faq from "../../components/landingPage/Faq";
import RiskGroup from "../../components/landingPage/RiskGroup";
import Covid from "../../components/landingPage/Covid";
import ImportantInfo from "../../components/landingPage/ImportantInfo";
import Contact from "../../components/landingPage/Contact";
import ProcedureDetails from "../../components/landingPage/ProcedureDetails";
import WhyUs from "../../components/landingPage/WhyUs";
import ButtonDrw from "../../components/landingPage/ButtonDrw";

import {
  GTM_WIDEO,
  GTM_CHIRURGIA,
  GTM_DERMATOLOGIA,
  GTM_E_ONKOLOGIA,
  GTM_NIECHIRURGIA,
  GTM_ONKOLOGIA,
  GTM_LEAD_UMOW_SIE,
} from "../../config/gtm_classes";

const GTM_LOCATION = "gdynia-dermatoskopowe";

//styles
import "../../styles/home.scss";

import SEO from "../../components/SEO";
import CallCta from "../../components/CallCta";
import ButtonApptNow from "../../components/landingPage/ButtonApptNow";

const REVIEWS = [
  {
    author: "Stefan R.",
    city: "Gdynia",
    review:
      "Bardzo szybkie terminy i ciągły kontakt z rejestracją. Samo badanie profesjonalne, wizyta trochę ponad 30 minut, ale mam sporo znamion.",
  },
  {
    author: "Marta G.",
    city: "Gdynia",
    review:
      "Panie w rejestracji bardzo pomocne, lekarz również miły i kompetentny. Faktycznie tak jak piszą badają wszystie znamiona i nie pozostawiają człowieka samemu.",
  },
  {
    author: "Barbara F.",
    city: "Gdynia",
    review:
      "Bardzo miła i kompetentna Pani Doktor. Wszystkie pieprzyki przebadane i już umówiłam się na badanie kontrolne.",
  },
];

const PRICES = [
  {
    isOpened: true,
    categoryName: "Badanie znamion - wideodermatoskopia",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_WIDEO}`}
        data-apptnow-widget="e4e10ee0-b217-11ed-9b40-ad6445aab55c"
      />
    ),
    items: [
      {
        price: "380",
        label: "wideodermatoskopia wszystkich znamion - osoba dorosła ",
        sub:
          "z mapowaniem wytypowanych przez lekarza  zmian wymagających monitorowania",
      },
      {
        price: "280",
        label:
          "wideodermatoskopia wszystkich znamion - dziecko do 12 roku życia",
        sub:
          "z mapowaniem wytypowanych przez lekarza  zmian wymagających monitorowania",
      },
      {
        price: "200",
        label:
          "wideodermatoskopia - wizyta kontrolna do 6 mies. od pełnego badania",
        sub:
          "badanie wytypowanych przez lekarza na poprzedniej wizycie znamion do monitorowania",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Chirurgiczne usuwanie znamion",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_CHIRURGIA}`}
        data-apptnow-widget="58a59800-b218-11ed-9b40-ad6445aab55c"
      />
    ),
    items: [
      {
        price: "200",
        label: "konsultacja chirurgiczna",
        sub: "Zabiegi chirurgiczne są wykonywane u pacjentów pełnoletnich",
      },
      {
        price: "480",
        label: "usunięcie 1 znamienia do 1,5cm",
        sub:
          "w standardowej lokalizacji - tułów/kończyny - w cenie kontrola, zdjęcie szwów",
      },
      {
        price: "280",
        label:
          "*usunięcie dodatkowego znamienia w trakcie jednej wizyty do 1,5cm",
        sub: "w standardowej lokalizacji - tułów/kończyny",
      },
      {
        price: "580",
        label:
          "usunięcie 1 znamienia powyżej 1,5cm lub w szczególnej lokalizacji np. twarz",
        sub: "w cenie kontrola, zdjęcie szwów",
      },
      {
        price: "350",
        label:
          "*usunięcie dodatkowego znamienia w trakcie jednej wizyty powyżej 1,5cm lub w szczególnej lokalizacji np. twarz",
      },
      {
        price: "150",
        label: "badanie histopatologiczne jednej zmiany",
      },
      {
        price: "0",
        label: "wizyta kontrolna po zabiegu ze zdjęciem szwów",
        sub: "*dla pacjentów, którzy odbyli zabieg w Twoje Znamiona",
      },
      {
        price: "100",
        label: "zdjęcie szwów",
        sub: "*dla pacjentów, którzy wykonali zabieg poza Twoje Znamiona",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Konsultacje onkologiczne - czerniak",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_E_ONKOLOGIA}`}
        data-apptnow-widget="57c029a0-9b25-11ed-b366-89b05425b063"
      />
    ),
    items: [
      {
        price: "200",
        label: "e-wizyta - wideokonferencja",
      },
    ],
  },
  // {
  //   isOpened: false,
  //   categoryName: "Konsultacje dermatologiczne",
  //   button: () => (
  //     <ButtonApptNow
  //       className={`${GTM_LOCATION} ${GTM_DERMATOLOGIA}`}
  //       data-apptnow-widget="3103def0-ca38-11ed-a8c2-eb76102814d8"
  //     />
  //   ),
  //   items: [
  //     {
  //       price: "250",
  //       label: "konsultacja dermatologiczna",
  //       sub: "inne schorzenia skóry: trądzik, łuszczyca, grzybica itp.",
  //     },
  //   ],
  // },
];

const OFFERS = [
  {
    type: "badanie",
    title: "Badanie znamion",
    desc:
      "Dermatoskopowe badanie wszystkich znamion na skórze w formie wideodermatoskopii z mapowaniem zmian wytypowanych przez lekarza do obserwacji oraz archiwizacją wykonanych zdjęć.",
    link: "/gdansk/dermatoskopowe-badanie-znamion-wideodermatoskopia#cennik",
  },
  {
    type: "chirurgiczne",
    title: "Usuwanie znamion",
    desc:
      "Chirurgiczne usuwanie podejrzanych pieprzyków wytypowanych przez lekarza w trakcie badania wideodermatoskopowego z wykonaniem histopatologicznej oceny usuniętej zmiany skórnej. Nieinwazyjne usuwanie łagodnych znamion laserem lub metodą krioterapii.",
    link: "/gdansk/usuwanie-znamion-pieprzykow",
  },
  {
    type: "onkologia",
    title: "Konsultacje onkologiczne",
    desc:
      "Konsultacja onkologiczna w zakresie nowotworów skóry, w tym czerniaka złośliwego. Pacjent otrzymuje zalecenia po usunięciu zmiany nowotworowej, dotyczące badań kontrolnych, leczenia uzupełniającego, a także leczenia zaawansowanego czerniaka.",
    link: "/poznan/konsultacje-onkologiczne-leczenie-czerniaka",
  },
];

const INFOS = [
  {
    title: "Ile trwa badanie?",
    desc:
      "Badanie wszystkich znamion - 30-40 min. Badanie kontrolne znamion wyznaczonych przez lekarza - 15-20 min.",
  },
  {
    title: "Jak się przygotować do badania?",
    desc:
      "Przed badaniem należy wykonać demakijaż oraz skrócić owłosienia w miejscach, w których znajdują się znamiona, celem poprawy jakości obrazu dermatoskopowego.",
  },
  {
    title: "Jakim urządzeniem badamy?",
    desc:
      "Pacjent badany jest za pomocą nowoczesnego sprzętu - wideodermatoskopu Fotofinder Portable Medicam 1000 Full HD",
  },
];

const CtaUmowSie = () => {
  return (
    <Link
      to="/gdansk/dermatoskopowe-badanie-znamion-wideodermatoskopia#cennik"
      title="Umów się"
      className={GTM_LEAD_UMOW_SIE}
    >
      Umów się
    </Link>
  );
};
const CtaCennik = () => {
  return (
    <Link
      to="/gdansk/dermatoskopowe-badanie-znamion-wideodermatoskopia#cennik"
      title="Cennik"
      className={GTM_LEAD_UMOW_SIE}
    >
      Ile to kosztuje?
    </Link>
  );
};

const GdyniaBadanieZnamionPage = ({ graph, location }) => {
  const FAQS = [
    {
      image: graph.faqs[1].image,
      imageMobile: graph.faqs[1].imageMobile,
      title: "Czy mam zwiększone ryzyko raka ?",
      text:
        "Jeśli posiadasz jasną karnację, piegi lub liczne znamiona na skórze, posiadasz znamiona których średnica przekracza 5 mm, bądź doznałeś poparzeń słonecznych, w Twojej rodzinie występowały nowotwory skóry, znajdujesz się w grupie podwyższonego ryzyka rozwoju nowotworu skóry. Osoby znajdujące się w grupie podwyższonego ryzyka, powinny poddać się badaniu dwa razy w roku oraz każdorazowo w przypadku zmiany budzącej niepokój. Wskazaniem do dermatoskopii są również naczyniaki, zmiany cylindryczne, rogowacenie łojotokowe naskórka, zmiany w postaci brodawek i świerzb, zmiany o charakterze dermatofibroma (histiocytoma).",
    },
    {
      image: graph.faqs[2].image,
      imageMobile: graph.faqs[2].imageMobile,
      title: "Czy wszystkie znamiona powinno się badać?",
      text:
        "Tak, ważne jest, żeby nie pominąć żadnej części ciała w trakcie badania znamion. Stąd też nazwa badanie znamion całego ciała lub badanie znamion z mapowaniem ciała. Często zdarza się, że znajdujemy niepokojące zmiany w miejscach, których sam Pacjent nie mógł łatwo zobaczyć.",
    },
  ];

  return (
    <Layout constactCtaComponent={<CtaUmowSie />} location={location}>
      <SEO
        title="Centrum Badania Znamion w Gdyni - Dermatoskopia - twojeznamiona.pl"
        description="Gdynia - kompleksowa diagnostyka znamion. Badamy wszystkie pieprzyki na ciele. Wideodermatoskopia z mapowaniem. Dlaczego dermatoskopia jest najlepszą metodą diagnostyki zmian skórnych? Ile to kosztuje - cennik. Dermatologia."
        keywords="badanie znamion, gdynia, dermatoskopia"
      />
      <Hero
        h1="Gdyńskie Centrum Dermatoskopowego Badania Znamion"
        oneLiner="Wszystkie swoje działania skupiamy na kompleksowej diagnostyce znamion, aby wyeliminować nowotwory skóry z życia milionów ludzi."
        oneLinerMore="Upewnij się, że czerniak nie ukrywa się w jednym z niepozornych pieprzyków na Twojej skórze."
        ctaPrimary={<CtaUmowSie />}
        ctaSecondary={<CtaCennik />}
      />
      <Offer className="home_offer" data={OFFERS} />

      <Melanoma className="home_melanoma" />
      <WhyUs className="home_why_us with_no_padding_top" />
      {/* <PriceList className="home_price_list" data={PRICES} /> */}

      <Contact
        className="home_contact"
        where="w okolicach Gdyni"
        city="Gdynia"
        isOnline={false}
      />
      {/* <Location
        className="home_location"
        coords={{
          lat: 54.4803673,
          lng: 18.5528634,
        }}
        defaultZoom={14}
        googleMarker={{
          data: ["Al. Zwycięstwa 241/3", "81-521 Gdynia"],
          link: "https://maps.app.goo.gl/EezBRGPxDqnETGjW6",
        }}
        additionalDetails="Centrum Badania Znamion znajduje się na parterze w Klinice Orłowo, przy ul. Al. Zwycięstwa 241/3 w Gdyni, dzielnica Orłowo. Wejście do ośrodka znajduję się od strony patio. Parking ogólnodostępny, płatny"
        locationInfo={[
          "Klinika Orłowo",
          "Al. Zwycięstwa 241/3",
          "81-521 Gdynia",
        ]}
        hoursInfo={["pon. - pt.: 8:00⁃20:00"]}
        transportationInfo={[
          "W bezpośredniej okolicy znajduje się 13 przystanków autobusowych,",
          "m.in. Orłowo SKM - Orłowska, Orłowo SKM - 'Klif', Nawigatorów 02",
        ]}
      /> */}
      {/* <Gallery
        className="home_gallery"
        data={graph.gallery}
        city="gdynia"
        imageTitlePostfix="w Gdyni"
      /> */}

      <div style={{ marginTop: "80px" }}></div>
      <Reviews className="home_reviews " data={REVIEWS} />

      <ProcedureDetails
        className="home_two_col_section_right_img"
        h2="Przebieg badania wideodermatoskopowego w Gdyni"
      />
      <ImportantInfo className="home_important_info" data={INFOS} />
      <Dermoscopy
        className="home_two_col_section_right_img with_no_padding_top"
        reverse={false}
      />
      <Videodermoscopy className="home_two_col_section_left_img" />
      <RiskGroup className="home_risk_group" />
      <Surgery className="home_two_col_section_left_img" reverse={false} />
      <Faq className="home_faq" data={FAQS} />
      <Abcde className="home_abcde" />
      <Oncology className="home_two_col_section_left_img with_no_padding_top with_no_padding_bottom" />
      <Cta className="home_cta" constactCtaComponent={<CtaUmowSie />} />
      <CallCta />
    </Layout>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        ImageGallery1: allFile(
          filter: { relativePath: { regex: "/lodz1.jpg/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery2: allFile(
          filter: { relativePath: { regex: "/lodz2.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery3: allFile(
          filter: { relativePath: { regex: "/lodz3.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery5: allFile(
          filter: { relativePath: { regex: "/lodz4.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq1Image: allFile(filter: { relativePath: { regex: "/faq1.png/" } }) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 144, maxHeight: 269, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq2Image: allFile(filter: { relativePath: { regex: "/faq2.png/" } }) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 144, maxHeight: 269, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq1ImageMobile: allFile(
          filter: { relativePath: { regex: "/faq-1-mobile.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 288, maxHeight: 179, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq2ImageMobile: allFile(
          filter: { relativePath: { regex: "/faq-2-mobile.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 288, maxHeight: 179, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <GdyniaBadanieZnamionPage
        graph={{
          gallery: [
            data.ImageGallery3.nodes[0],
            data.ImageGallery1.nodes[0],
            data.ImageGallery2.nodes[0],
            data.ImageGallery5.nodes[0],
          ],
          faqs: {
            1: {
              image: data.Faq1Image.nodes[0],
              imageMobile: data.Faq1ImageMobile.nodes[0],
            },
            2: {
              image: data.Faq2Image.nodes[0],
              imageMobile: data.Faq2ImageMobile.nodes[0],
            },
          },
        }}
        {...props}
      />
    )}
  />
);
